// -------------------------------
// Typography Settings
// -------------------------------

// Dependencies
// -------------------------------
@use 'colors' as *;
@use '../utils/relative-type' as *;
// -------------------------------

// Font-face Rules
// -------------------------------
//
// 'font-display: swap;' ensures that all text will be rendered with a fallback system font until custom font is ready.
//

@font-face {
    font-display: swap;
    font-family: 'filsonpro';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/FilsonPro/FilsonPro-Medium.ttf');
}

// Typefaces
// -------------------------------

$font-stack-fallback: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !default;
$base-font-family: var(--type-font-family), $font-stack-fallback !default;
$base-font-family-title: var(--type-font-family-title), $font-stack-fallback !default;
$base-font-family-monospace: Menlo, Monaco, Consolas, 'Droid Sans Mono',
    'Courier New', monospace !default;

// Base Font Settings
// -------------------------------

$base-color: $text-color !default;
$base-font-size: 18 !default;
$base-font-weight: 400 !default;
$base-line-height: 1.5 !default;

// Heading Settings
// -------------------------------

$heading-color: $black !default;
$heading-font-family: $base-font-family-title !default;
$heading-font-weight: 400 !default;
$heading-line-height: $base-line-height !default;

// Font Sizes
// -------------------------------

$font-size-tiny: 12 !default; // TODO: Should we use such small size?
$font-size-small: 14 !default;
$font-size-h6: 16 !default;
$font-size-h5: 18 !default;
$font-size-h4: 20 !default;
$font-size-h3: 24 !default;
$font-size-h2: 30 !default;
$font-size-h1: 36 !default;
$font-size-mega: 50 !default;
$font-size-giga: 60 !default;

// Line Heights
// -------------------------------
//
// We're only adjusting values when fonts get large.
//

$line-height-h3: 1.3 !default;
$line-height-h2: 1.2 !default;
$line-height-h1: 1.2 !default;
$line-height-mega: 1.2 !default;
$line-height-giga: 1.2 !default;

// Max line-length (aka measure) to improve readability of editorial content.
// -------------------------------

$max-line-length: var(--type-max-line-length) !default;
