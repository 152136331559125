// -------------------------------
// Color Settings
// -------------------------------
//
// 1. Please use JSDoc comments to identify all the color "sections".
// 2. This is so that when the theme is built, the 'sass-export' Node package can correctly sub-divide all the colors into appropriate groups for Storybook.
//
// TODO: Do we need to use color names generated from: http://chir.ag/projects/name-that-color/ for basic boilerplate color palette?

/**
 * @sass-export-section="main theme colours"
 */

$brand: #{'rgb(var(--color-brand))'} !default;
$text-color: #{'rgb(var(--color-text))'} !default;
$background-color: #{'rgb(var(--color-background))'} !default;

/**
 * @sass-export-section="neutral colours"
 */

$black: #{'rgb(var(--color-black))'};
$white: #{'rgb(var(--color-white))'};

/**
 * @sass-export-section="other accent colours"
 */

$success: #{'rgb(var(--color-accent-positive))'} !default;
$error: #{'rgb(var(--color-accent-negative))'} !default;
$notify: #{'rgb(var(--color-accent-neutral))'} !default;
