// -------------------------------
// Config Settings
// -------------------------------

// Dependencies
// -------------------------------
@use '../utils/relative-type' as *;
// -------------------------------

// Page / Layout
// -------------------------------

// Replace NULL with rem() value to set a max-width for site.
$site-width: rem(1600) !default;

// Spacing and gutters
// -------------------------------
//
// Use for margin|padding, and gap in flex|grid layouts.

$gutter-xs: var(--gutter-xs) !default;
$gutter-s: var(--gutter-s) !default;
$gutter-m: var(--gutter-m) !default;
$gutter-l: var(--gutter-l) !default;
$gutter-xl: var(--gutter-xl) !default;

$gutter-clamp-l: clamp(#{$gutter-m}, 4vw, #{$gutter-l}) !default;
$gutter-clamp-xl: clamp(#{$gutter-m}, 5vw, #{$gutter-xl}) !default;

// Grid / Flex
// -------------------------------

$grid-item-min-width: rem(200) !default; // Stop grid columns getting too small.
$grid-item-max-width: rem(280) !default; // Or too large.

// Icons
// -------------------------------

$icon-size-s: rem(16) !default;
$icon-size-m: rem(24) !default;
$icon-size-l: rem(32) !default;

// Breakpoints
// -------------------------------
//
// NOTE: We can't use CSS variables in media (or container) queries!

$breakpoints: (
    xlarge: 1280px,
    large: 1024px,
    medium: 768px,
    small: 568px,
);

// Layers (i.e. z-index)
// -------------------------------
//
// Implementation taken from http://www.sitepoint.com/better-solution-managing-z-index-sass/
//
// We're using native HTML <dialog> for modals, so the 'modal' layer is probably redundant now, unless we need to "mimic" modal behaviour.

$z-layers: (
    modal: 1000,
    popup: 999,
    default: 1,
    reset: 0,
    below: -1,
);

// Animations
// -------------------------------

$animate-class: '.page:not(.page-edit-mode) .animate' !default;
$page-edit: '.page:not(.page-edit-mode)' !default;
$speed: 0.3s;

// Misc
// -------------------------------

$base-image-path: '../images/interface' !default;
