// -------------------------------
// Typography
// -------------------------------

// SASS Core + 3rd Party Libs
// -------------------------------
// @use 'sass:math';
// -------------------------------

// Dependencies
// -------------------------------
@use '../utils' as *;
@use '../settings' as *;
// -------------------------------

// Element Styles
// -------------------------------
//
// Font smoothing has been removed - see https://ui.dev/rwd/articles/font-smoothing

html {
    font-size: rem($base-font-size);
}

body {
    @include fluid-type;
    font-family: $base-font-family;
}

h1,
h2,
h3,
h4,
h5,
h6,
[class*='heading--'] {
    font-family: $heading-font-family;
    font-weight: $heading-font-weight;
}

.heading--giga {
    @include fluid-type($font-size-h1, $font-size-giga, $line-height-giga);
    margin-block-end: clamp($gutter-m, 4vw, $gutter-l);
}

.heading--mega {
    @include fluid-type($font-size-h2, $font-size-mega, $line-height-mega);
    margin-block-end: clamp($gutter-m, 4vw, $gutter-l);
}

h1,
.heading--1 {
    @include fluid-type($font-size-h3, $font-size-h1, $line-height-h1);
    margin-block-end: $gutter-m;
}

h2,
.heading--2 {
    @include fluid-type($font-size-h4, $font-size-h2, $line-height-h2);
    margin-block-end: $gutter-m;
}

h3,
.heading--3 {
    @include fluid-type($font-size-h5, $font-size-h3, $line-height-h3);
    margin-block-end: $gutter-m;
}

h4,
.heading--4 {
    font-size: rem($font-size-h4);
    margin-block-end: $gutter-s;
}

h5,
.heading--5 {
    font-size: rem($font-size-h5);
    margin-block-end: $gutter-xs;
}

h6,
.heading--6 {
    font-size: rem($font-size-h6);
    margin-block-end: $gutter-xs;
}

// Margins on other Prose Elements
// -------------------------------

p,
ul:not([class]),
ol:not([class]) {
    margin-block-end: $gutter-m;
}

// Validation Styles
// -------------------------------

.error {
    color: $error;
}

.success {
    color: $success;
}

// Miscellaneous Styles
// -------------------------------

p:not([class]),
li:not([class]),
dd,
small {
    max-inline-size: $max-line-length;
}

button,
input,
select,
textarea {
    font: inherit;
}

caption,
th,
td {
    font-weight: normal;
    text-align: start;
}

address {
    font-style: normal;
}

small {
    font-size: rem($font-size-small);
}

code {
    font-family: $base-font-family-monospace;
}
