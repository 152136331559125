// -------------------------------
// Fluid Typography Mixin
// -------------------------------
//
// Fluid typography. Uses 'clamp()' to set MIN|MAX values, with a simple non-responsive fallback for older browsers.
//
// See https://piccalil.li/tutorial/fluid-typography-with-css-clamp.
//

// SASS Core + 3rd Party Libs
// -------------------------------
@use 'sass:math';
// -------------------------------

// Dependencies
// -------------------------------
@use '../settings' as *;
// -------------------------------

$min-width: 375;
$max-width: 1600;

@mixin fluid-type(
    $min-font: $font-size-small,
    $max-font: $base-font-size,
    $line-height: $base-line-height
) {
    // See https://chrisburnell.com/clamp-calculator
    $x: math.div(($max-font - $min-font), ($max-width - $min-width));
    $y: $max-font - $max-width * $x;
    $z: $x * 100;

    $clamp-min: #{math.div($min-font, 16)}rem;
    $clamp-ideal: calc(#{math.div($y, 16)}rem + #{$z}vw);
    $clamp-max: #{math.div($max-font, 16)}rem;

    font-size: clamp($clamp-min, $clamp-ideal, $clamp-max);
    line-height: $line-height;
}
