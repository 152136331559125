// -------------------------------
// Tables
// -------------------------------

// SASS Core + 3rd Party Libs
// -------------------------------
// @use 'sass:math';
// -------------------------------

// Dependencies
// -------------------------------
@use '../settings' as *;
@use '../utils' as *;
// -------------------------------

// Define mixin so we can style tables inside WYSIWYG too, without requiring CSS class to be added.
@mixin table {
    @include breakpoint(medium, min) {
        font-size: inherit;
    }
    font-size: $font-size-tiny;

    th,
    td {
        @include breakpoint(medium, min) {
            padding: $gutter-m;
        }
        border: rem(1) solid #{rgba(var(--color-black), 0.1)};
        padding: $gutter-s;
    }

    th {
        font-weight: bold;
    }

    caption {
        margin-block-end: $gutter-xs;
    }

    tr:nth-child(2n) {
        background-color: #{rgba(var(--color-black), 0.03)};
    }
}

table,
.table {
    @include table;

    // Responsive wrapper.
    &-wrapper[role='region'][tabindex] {
        @include focus;
        overflow: auto;

        &:hover {
            cursor: default;
        }
    }
}
