// -------------------------------
// Breakpoint Mixin
// -------------------------------
//
// breakpoint($name, $type)
//
// $name - Breakpoint map name, or px value
// $type - The type of break point required, either `min` or `max`. Defaults to `max`. `(optional)`
// @content - Nested selectors for your breakpoint
//

// SASS Core + 3rd Party Libs
// -------------------------------
// @use 'sass:math';
// -------------------------------

// Dependencies
// -------------------------------
@use '../settings' as *;
// -------------------------------

@mixin breakpoint($name, $type: max) {
    $width: 0;
    $query: 0;

    @if map-has-key($breakpoints, $name) {
        $width: map_get($breakpoints, $name);
    } @else if type_of($name) == 'number' {
        $width: $name;
    } @else {
        @warn 'Warning: `#{$name}` is not a valid breakpoint name.';
    }

    @if $type == max {
        $query: $width - 1px;
    } @else {
        $query: $width;
    }

    @media screen and (#{$type}-width: $query) {
        @content;
    }
}
