// -------------------------------
// Sidebar
// -------------------------------
//
// See https://every-layout.dev/layouts/sidebar/ for explanation of Flex behaviour.

// SASS Core + 3rd Party Libs
// -------------------------------
// @use 'sass:math';
// -------------------------------

// Dependencies
// -------------------------------
@use '../utils' as *;
@use '../settings' as *;
// -------------------------------

.sidebar {
    --sidebar-width: unset;

    $this: &;

    flex-basis: var(--sidebar-width);
    flex-grow: 1;

    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: $gutter-m;

        :not(#{$this}) {
            --content-min-width: 50%;

            flex-basis: 0;
            flex-grow: 999;
            min-inline-size: var(--content-min-width);
        }
    }
}
