// -------------------------------
// WYSIWYG (CMS editor "prose" content
// -------------------------------

// SASS Core + 3rd Party Libs
// -------------------------------
// @use 'sass:math';
// -------------------------------

// Dependencies
// -------------------------------
@use '../settings' as *;
@use '../utils' as *;
// -------------------------------

.wysiwyg {
    // Uses stack layout to handle spacing between prose elements, based on their rendered font size.
    &.stack {
        --stack-gutter: 1em;

        // Remove spacing for adjacent headings.
        :is(h2 + h3, h3 + h4, h4 + h5, h5 + h6) {
            --stack-gutter: 0;
        }
    }

    img {
        margin-inline: auto;
    }
}
