// -------------------------------
// Skiplinks
// -------------------------------

// SASS Core + 3rd Party Libs
// -------------------------------
// @use 'sass:math';
// -------------------------------

// Dependencies
// -------------------------------
@use '../settings' as *;
@use '../utils' as *;
// -------------------------------

.skiplinks {
    position: absolute;

    &__link {
        @include off-screen;
        background-color: $brand;
        color: #fff;
        display: inline-block;
        left: $gutter-m;
        min-inline-size: 10vw;
        padding: $gutter-s;
        text-align: center;
        z-index: z('default') + 1;
    }
}
