// -------------------------------
// CSS variables for dynamic theming.
// -------------------------------
//
// 1. CSS variables are global if they are defined inside ':root' element.
// 2. Theme overrides can be applied via HTML <style> blocks, JavaScript or by simply setting a new value in a Sass partial.
// 3. Color values are specified as a comma-separated list of RGB values. This allows us to leverage the Sass 'rgba()' function.
//
//      For example:
//      color: #{'rgba(var(--color-brand), .5)'};

:root {
    // Colors.
    // --color-brand: 239, 127, 150; // fails a11y
    --color-brand: 233, 82, 113; // still fails a11y
    --color-text: 0, 0, 0;
    --color-background: 255, 255, 255;
    --color-black: 0, 0, 0;
    --color-white: 255, 255, 255;
    --color-accent-positive: 114, 198, 108;
    --color-accent-negative: 223, 0, 45;
    --color-accent-neutral: 235, 201, 90;

    // Typography.
    --type-font-family: 'filsonpro';
    --type-font-family-title: 'filsonpro';
    --type-max-line-length: null; // Ideally should be no more than 85ch

    // Layout.
    --gutter-xs: 0.3125rem; // 5px
    --gutter-s: 0.625rem; // 10px
    --gutter-m: 1.25rem; // 20px
    --gutter-l: 2.5rem; // 40px
    --gutter-xl: 3.75rem; // 60px
}

// Dark theme: ensure black & white are NOT truly black & white.
@media (prefers-color-scheme: dark) {
    :root {
        --color-text: 243, 244, 244;
        --color-background: 34, 34, 34;
        --color-black: 34, 34, 34;
        --color-white: 244, 244, 244;
    }
}
