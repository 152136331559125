// -------------------------------
// Page
// -------------------------------
//

// SASS Core + 3rd Party Libs
// -------------------------------
// @use 'sass:math';
// -------------------------------

// Dependencies
// -------------------------------
@use '../utils' as *;
@use '../settings' as *;
// -------------------------------

.page {
    $this: &;

    display: grid;
    grid-template-rows: auto 1fr auto;
    margin-inline: auto;
    max-inline-size: $site-width;
    min-block-size: 100vh;

    &__sidebar {
        .sidebar {
            // Set explicit flex-basis (width) for page sidebar.
            --sidebar-width: #{rem(300)};
        }
    }

    // Main is a grid item, so this affects overflow behaviour on components like tables.
    .main {
        overflow-x: hidden;
    }
}
