// -------------------------------
// Wrapper
// -------------------------------
//
// Replaces '.section' and '.container' from old UI boilerplate. TBC

// SASS Core + 3rd Party Libs
// -------------------------------
// @use 'sass:math';
// -------------------------------

// Dependencies
// -------------------------------
@use '../utils' as *;
@use '../settings' as *;
// -------------------------------

.wrapper {
    --wrapper-max-width: #{rem(1024)};

    margin-inline: auto;
    max-inline-size: var(--wrapper-max-width);
    padding-inline: var(--gutter-m);

    &--xs {
        --wrapper-max-width: #{rem(640)};
    }

    &--s {
        --wrapper-max-width: #{rem(768)};
    }

    &--l {
        --wrapper-max-width: #{rem(1200)};
    }

    &--xl {
        --wrapper-max-width: #{rem(1600)};
    }

    &--fullbleed {
        --wrapper-max-width: 100vw;
        padding-inline: 0;
    }

    &--fit-content {
        --wrapper-max-width: unset;
        inline-size: fit-content;
    }
}
