// -------------------------------
// Off Screen mixin
// -------------------------------
//
// Hide content visually, but make available for screen readers. Reveal content if it receives :FOCUS.
//

// SASS Core + 3rd Party Libs
// -------------------------------
// @use 'sass:math';
// -------------------------------

// Dependencies
// -------------------------------
// @use 'settings' as *;
// -------------------------------

@mixin off-screen() {
    block-size: 1px;
    border: 0;
    clip: rect(0, 0, 0, 0);
    inline-size: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;

    &:focus {
        block-size: auto;
        clip: auto;
        inline-size: auto;
        margin: 0;
        overflow: visible;
    }
}
