// -------------------------------
// Button Group
// -------------------------------

// SASS Core + 3rd Party Libs
// -------------------------------
// @use 'sass:math';
// -------------------------------

// Dependencies
// -------------------------------
@use '../settings' as *;
@use '../utils' as *;
// -------------------------------

.button-group {
    display: flex;
    flex-wrap: wrap;
    gap: $gutter-s;

    &--centered {
        justify-content: center;
    }
}
