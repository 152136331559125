// -------------------------------
// Focus Mixin
// -------------------------------
//
// Adds :hover, :focus and :active styles
//

// SASS Core + 3rd Party Libs
// -------------------------------
// @use 'sass:math';
// -------------------------------

// Dependencies
// -------------------------------
@use '../settings' as *;
@use '../utils/relative-type' as *;
// -------------------------------

@mixin focus(
    $background: false,
    $inset: false,
    $opacity: false,
    $transform: false
) {
    transition: background-color 150ms ease-in-out, box-shadow 150ms ease-in-out,
        opacity 250ms ease-in-out, transform 150ms ease-in-out;

    &:hover {
        @if $background {
            &:not(:disabled, :invalid, :valid) {
                background-color: #{rgba(var(--color-brand), 0.1)};
            }
        }
        @if $opacity {
            opacity: 0.8;
        }
        cursor: pointer;
        text-decoration: none;
    }

    &:active {
        @if $transform {
            transform: scale(0.975);
        }
    }

    // See https://css-tricks.com/the-focus-visible-trick/
    &:focus-visible {
        box-shadow: 0 0 0 rem(4) $notify;
        outline: rem(4) solid transparent; // So high contrast mode works.

        /* stylelint-disable-next-line order/order */
        @if $inset {
            box-shadow: inset 0 0 0 rem(4) $notify;
        }
    }

    &:focus:not(:focus-visible) {
        box-shadow: rem(1) rem(1) rem(5) #{rgba(var(--color-black), 0.2)};
        outline: rem(4) solid transparent; // So high contrast mode works.
    }
}
