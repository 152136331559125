// -------------------------------
// Header
// -------------------------------

// SASS Core + 3rd Party Libs
// -------------------------------
// @use 'sass:math';
// -------------------------------

// Dependencies
// -------------------------------
@use '../settings' as *;
@use '../utils' as *;
// -------------------------------

.header {
    padding-block: $gutter-clamp-xl;

    &__home {
        @include focus;
        display: block;
        inline-size: fit-content;
        margin-inline: auto;
    }

    &__logo {
        max-inline-size: 40vw;
    }
}
