:root {
  --color-brand: 233, 82, 113;
  --color-text: 0, 0, 0;
  --color-background: 255, 255, 255;
  --color-black: 0, 0, 0;
  --color-white: 255, 255, 255;
  --color-accent-positive: 114, 198, 108;
  --color-accent-negative: 223, 0, 45;
  --color-accent-neutral: 235, 201, 90;
  --type-font-family: "filsonpro";
  --type-font-family-title: "filsonpro";
  --type-max-line-length: null;
  --gutter-xs: .3125rem;
  --gutter-s: .625rem;
  --gutter-m: 1.25rem;
  --gutter-l: 2.5rem;
  --gutter-xl: 3.75rem;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-text: 243, 244, 244;
    --color-background: 34, 34, 34;
    --color-black: 34, 34, 34;
    --color-white: 244, 244, 244;
  }
}

@font-face {
  font-display: swap;
  font-family: filsonpro;
  font-style: normal;
  font-weight: normal;
  src: url("FilsonPro-Medium.914b3bf4.ttf");
}

.button {
  color: inherit;
  cursor: pointer;
  padding: var(--gutter-s);
  background: none;
  text-decoration: none;
  transition: background-color .15s ease-in-out, box-shadow .15s ease-in-out, opacity .25s ease-in-out, transform .15s ease-in-out;
  display: inline-block;
}

.button:hover {
  opacity: .8;
  cursor: pointer;
  text-decoration: none;
}

.button:active {
  transform: scale(.975);
}

.button:focus-visible {
  box-shadow: 0 0 0 .25rem rgb(var(--color-accent-neutral));
  outline: .25rem solid #0000;
}

.button:focus:not(:focus-visible) {
  box-shadow: .0625rem .0625rem .3125rem rgba(var(--color-black), .2);
  outline: .25rem solid #0000;
}

.button:disabled {
  cursor: not-allowed;
  opacity: .6;
}

.button[class*="text"] {
  padding: var(--gutter-m) clamp(var(--gutter-m), 4vw, var(--gutter-l));
  border: .0625rem solid;
  border-radius: 2.5rem;
  font-size: 1.125rem;
}

.button--text-icon {
  align-items: center;
  gap: var(--gutter-s);
  justify-content: space-between;
  display: inline-flex;
}

.button--text-icon span {
  text-align: start;
}

.button--reverse {
  flex-direction: row-reverse;
}

.button--primary {
  background-color: rgb(var(--color-brand));
  color: rgb(var(--color-white));
}

.button--primary[class*="text"] {
  border-color: rgb(var(--color-brand));
}

.button--secondary {
  color: rgb(var(--color-brand));
}

.button--secondary[class*="text"] {
  border-color: rgb(var(--color-brand));
}

.button--positive {
  background-color: rgb(var(--color-accent-positive));
}

.button--positive[class*="text"] {
  border-color: rgb(var(--color-accent-positive));
}

.button--negative {
  background-color: rgb(var(--color-accent-negative));
  color: rgb(var(--color-white));
}

.button--negative[class*="text"] {
  border-color: rgb(var(--color-accent-negative));
}

.button--icon {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.button--small {
  padding: var(--gutter-xs);
  font-size: .875rem;
}

.button-group {
  gap: var(--gutter-s);
  flex-wrap: wrap;
  display: flex;
}

.button-group--centered {
  justify-content: center;
}

.footer {
  padding-block: clamp(var(--gutter-m), 5vw, var(--gutter-xl));
}

.footer__wrapper {
  align-items: center;
  gap: clamp(var(--gutter-m), 4vw, var(--gutter-l));
  flex-direction: column;
  justify-content: center;
  display: flex;
}

@media screen and (min-width: 768px) {
  .footer__wrapper {
    flex-direction: row;
  }
}

.footer__nav .flex {
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .footer__nav .flex {
    --flex-gap: clamp(var(--gutter-m), 4vw, var(--gutter-l));
    flex-direction: row;
  }

  .footer__item:not(:last-child) {
    position: relative;
  }

  .footer__item:not(:last-child) :after {
    content: "|";
    position: absolute;
    inset-inline-end: calc(clamp(var(--gutter-m), 4vw, var(--gutter-l)) * -.5 - 1px);
  }
}

.footer__link {
  color: inherit;
  text-decoration: none;
  transition: background-color .15s ease-in-out, box-shadow .15s ease-in-out, opacity .25s ease-in-out, transform .15s ease-in-out;
}

.footer__link:hover {
  cursor: pointer;
  text-decoration: none;
}

.footer__link:focus-visible {
  box-shadow: 0 0 0 .25rem rgb(var(--color-accent-neutral));
  outline: .25rem solid #0000;
}

.footer__link:focus:not(:focus-visible) {
  box-shadow: .0625rem .0625rem .3125rem rgba(var(--color-black), .2);
  outline: .25rem solid #0000;
}

.footer__link:hover {
  color: rgb(var(--color-brand));
  text-decoration: underline;
}

.header {
  padding-block: clamp(var(--gutter-m), 5vw, var(--gutter-xl));
}

.header__home {
  inline-size: -moz-fit-content;
  inline-size: fit-content;
  margin-inline: auto;
  transition: background-color .15s ease-in-out, box-shadow .15s ease-in-out, opacity .25s ease-in-out, transform .15s ease-in-out;
  display: block;
}

.header__home:hover {
  cursor: pointer;
  text-decoration: none;
}

.header__home:focus-visible {
  box-shadow: 0 0 0 .25rem rgb(var(--color-accent-neutral));
  outline: .25rem solid #0000;
}

.header__home:focus:not(:focus-visible) {
  box-shadow: .0625rem .0625rem .3125rem rgba(var(--color-black), .2);
  outline: .25rem solid #0000;
}

.header__logo {
  max-inline-size: 40vw;
}

.page-title {
  text-align: center;
}

.skiplinks {
  position: absolute;
}

.skiplinks__link {
  block-size: 1px;
  clip: rect(0, 0, 0, 0);
  inline-size: 1px;
  background-color: rgb(var(--color-brand));
  color: #fff;
  left: var(--gutter-m);
  min-inline-size: 10vw;
  padding: 0;
  padding: var(--gutter-s);
  text-align: center;
  z-index: 2;
  border: 0;
  margin: -1px;
  display: inline-block;
  position: absolute;
  overflow: hidden;
}

.skiplinks__link:focus {
  block-size: auto;
  clip: auto;
  inline-size: auto;
  margin: 0;
  overflow: visible;
}

table, .table {
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  table, .table {
    font-size: inherit;
  }
}

table th, table td, .table th, .table td {
  border: .0625rem solid rgba(var(--color-black), .1);
  padding: var(--gutter-s);
}

@media screen and (min-width: 768px) {
  table th, table td, .table th, .table td {
    padding: var(--gutter-m);
  }
}

table th, .table th {
  font-weight: bold;
}

table caption, .table caption {
  margin-block-end: var(--gutter-xs);
}

table tr:nth-child(2n), .table tr:nth-child(2n) {
  background-color: rgba(var(--color-black), .03);
}

table-wrapper[role="region"][tabindex], .table-wrapper[role="region"][tabindex] {
  transition: background-color .15s ease-in-out, box-shadow .15s ease-in-out, opacity .25s ease-in-out, transform .15s ease-in-out;
  overflow: auto;
}

table-wrapper[role="region"][tabindex]:hover, .table-wrapper[role="region"][tabindex]:hover {
  cursor: pointer;
  text-decoration: none;
}

table-wrapper[role="region"][tabindex]:focus-visible, .table-wrapper[role="region"][tabindex]:focus-visible {
  box-shadow: 0 0 0 .25rem rgb(var(--color-accent-neutral));
  outline: .25rem solid #0000;
}

table-wrapper[role="region"][tabindex]:focus:not(:focus-visible), .table-wrapper[role="region"][tabindex]:focus:not(:focus-visible) {
  box-shadow: .0625rem .0625rem .3125rem rgba(var(--color-black), .2);
  outline: .25rem solid #0000;
}

table-wrapper[role="region"][tabindex]:hover, .table-wrapper[role="region"][tabindex]:hover {
  cursor: default;
}

.wysiwyg.stack {
  --stack-gutter: 1em;
}

.wysiwyg.stack :is(h2 + h3, h3 + h4, h4 + h5, h5 + h6) {
  --stack-gutter: 0;
}

.wysiwyg img {
  margin-inline: auto;
}

.icon {
  block-size: 1em;
  fill: currentColor;
  inline-size: 1em;
}

.icon--small {
  block-size: 1rem;
  inline-size: 1rem;
}

.icon--medium {
  block-size: 1.5rem;
  inline-size: 1.5rem;
}

.icon--large {
  block-size: 2rem;
  inline-size: 2rem;
}

html {
  text-size-adjust: none;
}

*, :after, :before {
  box-sizing: border-box;
}

* {
  vertical-align: baseline;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

html, body {
  background-color: rgb(var(--color-background));
  color: rgb(var(--color-text));
  min-block-size: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  html, body {
    scroll-behavior: smooth;
  }
}

ol[class], ul[class] {
  list-style: none;
}

ol ol {
  list-style-type: lower-alpha;
}

ol ol ol {
  list-style-type: lower-roman;
}

ol:not([class]), ul:not([class]) {
  padding-inline-start: var(--gutter-l);
}

[type="submit"], label {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  block-size: auto;
  max-inline-size: 100%;
  display: block;
}

@media (prefers-color-scheme: dark) {
  img {
    opacity: .8;
    transition: opacity .5s ease-in-out;
  }

  img:hover {
    opacity: 1;
  }
}

svg {
  max-inline-size: 100%;
  flex-shrink: 0;
}

input, textarea, select {
  color: inherit;
  vertical-align: middle;
}

button, a {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  -webkit-tap-highlight-color: #0000;
}

button > * {
  pointer-events: none;
}

a:not([class]) {
  color: rgb(var(--color-brand));
}

a:not([class]):hover {
  text-decoration: none;
}

sub, sup {
  vertical-align: revert;
}

iframe {
  max-inline-size: 100%;
  border: 0;
  margin: 0 auto;
  display: block;
}

hr {
  margin-block: var(--gutter-m);
  border: .0625rem solid;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

html {
  font-size: 1.125rem;
}

body {
  font-size: clamp(.875rem, .798469rem + .326531vw, 1.125rem);
  line-height: 1.5;
  font-family: var(--type-font-family), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

h1, h2, h3, h4, h5, h6, [class*="heading--"] {
  font-family: var(--type-font-family-title), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 400;
}

.heading--giga {
  margin-block-end: clamp(var(--gutter-m), 4vw, var(--gutter-l));
  font-size: clamp(2.25rem, 1.79082rem + 1.95918vw, 3.75rem);
  line-height: 1.2;
}

.heading--mega {
  margin-block-end: clamp(var(--gutter-m), 4vw, var(--gutter-l));
  font-size: clamp(1.875rem, 1.49235rem + 1.63265vw, 3.125rem);
  line-height: 1.2;
}

h1, .heading--1 {
  margin-block-end: var(--gutter-m);
  font-size: clamp(1.5rem, 1.27041rem + .979592vw, 2.25rem);
  line-height: 1.2;
}

h2, .heading--2 {
  margin-block-end: var(--gutter-m);
  font-size: clamp(1.25rem, 1.05867rem + .816327vw, 1.875rem);
  line-height: 1.2;
}

h3, .heading--3 {
  margin-block-end: var(--gutter-m);
  font-size: clamp(1.125rem, 1.0102rem + .489796vw, 1.5rem);
  line-height: 1.3;
}

h4, .heading--4 {
  margin-block-end: var(--gutter-s);
  font-size: 1.25rem;
}

h5, .heading--5 {
  margin-block-end: var(--gutter-xs);
  font-size: 1.125rem;
}

h6, .heading--6 {
  margin-block-end: var(--gutter-xs);
  font-size: 1rem;
}

p, ul:not([class]), ol:not([class]) {
  margin-block-end: var(--gutter-m);
}

.error {
  color: rgb(var(--color-accent-negative));
}

.success {
  color: rgb(var(--color-accent-positive));
}

p:not([class]), li:not([class]), dd, small {
  max-inline-size: var(--type-max-line-length);
}

button, input, select, textarea {
  font: inherit;
}

caption, th, td {
  text-align: start;
  font-weight: normal;
}

address {
  font-style: normal;
}

small {
  font-size: .875rem;
}

code {
  font-family: Menlo, Monaco, Consolas, Droid Sans Mono, Courier New, monospace;
}

.flex {
  --flex-gap: var(--gutter-m);
  gap: var(--flex-gap, 1rem);
  flex-wrap: wrap;
  display: flex;
}

.flex--column {
  flex-direction: column;
}

.flex--row-reverse {
  flex-direction: row-reverse;
}

.flex--column-reverse {
  flex-direction: column-reverse;
}

.flex--no-gap {
  --flex-gap: 0;
}

.flex--gap-xs {
  --flex-gap: var(--gutter-xs);
}

.flex--gap-s {
  --flex-gap: var(--gutter-s);
}

.flex--gap-l {
  --flex-gap: clamp(var(--gutter-m), 4vw, var(--gutter-l));
}

.flex--gap-xl {
  --flex-gap: clamp(var(--gutter-m), 5vw, var(--gutter-xl));
}

.flex--ai-center {
  align-items: center;
}

.flex--ai-start {
  align-items: flex-start;
}

.flex--ai-end {
  align-items: flex-end;
}

.flex--jc-center {
  justify-content: center;
}

.flex--jc-end {
  justify-content: flex-end;
}

.flex--jc-between {
  justify-content: space-between;
}

.flex--jc-evenly {
  justify-content: space-evenly;
}

.flex--grow-all > * {
  flex-grow: 1;
}

.flex > * {
  margin: 0;
}

.flex > li {
  max-inline-size: none;
}

[class*="grid"] {
  --grid-gap: var(--gutter-m);
  gap: var(--grid-gap, 1rem);
  display: grid;
}

[class*="grid"] > * {
  margin: 0;
}

[class*="grid"] > li {
  max-inline-size: none;
}

.grid {
  grid-template-columns: repeat(auto-fit, minmax(min(12.5rem, 100%), 1fr));
}

.grid--centered {
  justify-content: center;
}

.grid--no-gap {
  --grid-gap: 0;
}

.grid--gap-xs {
  --grid-gap: var(--gutter-xs);
}

.grid--gap-s {
  --grid-gap: var(--gutter-s);
}

.grid--gap-l {
  --grid-gap: clamp(var(--gutter-m), 4vw, var(--gutter-l));
}

.grid--gap-xl {
  --grid-gap: clamp(var(--gutter-m), 5vw, var(--gutter-xl));
}

.grid--2-cols\@small {
  grid-template-columns: repeat(auto-fit, minmax(min(6.25rem, 100%), 1fr));
}

@supports (container-type: inline-size) {
  @container (min-width: 25rem) {
    .grid--asymmetric {
      grid-template-columns: minmax(12.5rem, 66.66%) minmax(12.5rem, 33.33%);
    }
  }
}

@supports not (container-type: inline-size) {
  @media screen and (min-width: 568px) {
    .grid--asymmetric {
      grid-template-columns: minmax(12.5rem, 66.66%) minmax(12.5rem, 33.33%);
    }
  }
}

@supports (container-type: inline-size) {
  @container (min-width: 25rem) {
    .grid--75-25 {
      grid-template-columns: minmax(12.5rem, 75%) minmax(12.5rem, 25%);
    }
  }
}

@supports not (container-type: inline-size) {
  @media screen and (min-width: 568px) {
    .grid--75-25 {
      grid-template-columns: minmax(12.5rem, 75%) minmax(12.5rem, 25%);
    }
  }
}

@supports (container-type: inline-size) {
  @container (min-width: 25rem) {
    .grid--25-75 {
      grid-template-columns: minmax(12.5rem, 25%) minmax(12.5rem, 75%);
    }
  }
}

@supports not (container-type: inline-size) {
  @media screen and (min-width: 568px) {
    .grid--25-75 {
      grid-template-columns: minmax(12.5rem, 25%) minmax(12.5rem, 75%);
    }
  }
}

@supports (container-type: inline-size) {
  .grid-container {
    container-type: inline-size;
  }
}

.page {
  max-inline-size: 100rem;
  min-block-size: 100vh;
  grid-template-rows: auto 1fr auto;
  margin-inline: auto;
  display: grid;
}

.page__sidebar .sidebar {
  --sidebar-width: 18.75rem;
}

.page .main {
  overflow-x: hidden;
}

.sidebar {
  --sidebar-width: unset;
  flex-basis: var(--sidebar-width);
  flex-grow: 1;
}

.sidebar-wrapper {
  gap: var(--gutter-m);
  flex-wrap: wrap;
  display: flex;
}

.sidebar-wrapper :not(.sidebar) {
  --content-min-width: 50%;
  min-inline-size: var(--content-min-width);
  flex-grow: 999;
  flex-basis: 0;
}

.stack {
  --stack-gutter: var(--gutter-m);
}

.stack > * + * {
  margin-block-start: var(--stack-gutter, 1em);
}

.stack > * {
  margin-block-end: 0;
}

.stack--gutter-xs {
  --stack-gutter: var(--gutter-xs);
}

.stack--gutter-s {
  --stack-gutter: var(--gutter-s);
}

.stack--gutter-l {
  --stack-gutter: clamp(var(--gutter-m), 4vw, var(--gutter-l));
}

.stack--gutter-xl {
  --stack-gutter: clamp(var(--gutter-m), 5vw, var(--gutter-xl));
}

.wrapper {
  --wrapper-max-width: 64rem;
  max-inline-size: var(--wrapper-max-width);
  padding-inline: var(--gutter-m);
  margin-inline: auto;
}

.wrapper--xs {
  --wrapper-max-width: 40rem;
}

.wrapper--s {
  --wrapper-max-width: 48rem;
}

.wrapper--l {
  --wrapper-max-width: 75rem;
}

.wrapper--xl {
  --wrapper-max-width: 100rem;
}

.wrapper--fullbleed {
  --wrapper-max-width: 100vw;
  padding-inline: 0;
}

.wrapper--fit-content {
  --wrapper-max-width: unset;
  inline-size: -moz-fit-content;
  inline-size: fit-content;
}

.sr-only {
  block-size: 1px;
  clip: rect(0, 0, 0, 0);
  inline-size: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only:focus {
  block-size: auto;
  clip: auto;
  inline-size: auto;
  margin: 0;
  overflow: visible;
}

.u-hidden {
  display: none;
}

[class*="u-m-block"] {
  --marg-block-gutter: var(--gutter-m);
  margin-block: var(--marg-block-gutter, 1rem);
}

[class*="u-m-inline"] {
  --marg-inline-gutter: var(--gutter-m);
  margin-inline: var(--marg-inline-gutter, 1rem);
}

.u-m-block--0 {
  --marg-block-gutter: 0;
}

.u-m-block--xs {
  --marg-block-gutter: var(--gutter-xs);
}

.u-m-block--s {
  --marg-block-gutter: var(--gutter-s);
}

.u-m-block--l {
  --marg-block-gutter: clamp(var(--gutter-m), 4vw, var(--gutter-l));
}

.u-m-block--xl {
  --marg-block-gutter: clamp(var(--gutter-m), 5vw, var(--gutter-xl));
}

.u-m-inline--0 {
  --marg-inline-gutter: 0;
}

.u-m-inline--xs {
  --marg-inline-gutter: var(--gutter-xs);
}

.u-m-inline--s {
  --marg-inline-gutter: var(--gutter-s);
}

.u-m-inline--l {
  --marg-inline-gutter: clamp(var(--gutter-m), 4vw, var(--gutter-l));
}

.u-m-inline--xl {
  --marg-inline-gutter: clamp(var(--gutter-m), 5vw, var(--gutter-xl));
}

[class*="u-p-block"] {
  --pad-block-gutter: var(--gutter-m);
  padding-block: var(--pad-block-gutter, 1rem);
}

[class*="u-p-inline"] {
  --pad-inline-gutter: var(--gutter-m);
  padding-inline: var(--pad-inline-gutter, 1rem);
}

.u-p-block--0 {
  --pad-block-gutter: 0;
}

.u-p-block--xs {
  --pad-block-gutter: var(--gutter-xs);
}

.u-p-block--s {
  --pad-block-gutter: var(--gutter-s);
}

.u-p-block--l {
  --pad-block-gutter: clamp(var(--gutter-m), 4vw, var(--gutter-l));
}

.u-p-block--xl {
  --pad-block-gutter: clamp(var(--gutter-m), 5vw, var(--gutter-xl));
}

.u-p-inline--0 {
  --pad-inline-gutter: 0;
}

.u-p-inline--xs {
  --pad-inline-gutter: var(--gutter-xs);
}

.u-p-inline--s {
  --pad-inline-gutter: var(--gutter-s);
}

.u-p-inline--l {
  --pad-inline-gutter: clamp(var(--gutter-m), 4vw, var(--gutter-l));
}

.u-p-inline--xl {
  --pad-inline-gutter: clamp(var(--gutter-m), 5vw, var(--gutter-xl));
}

/*# sourceMappingURL=index.19967d4f.css.map */
