// -------------------------------
// Button Reset Mixin
// -------------------------------
//
// <button>
// <a class="button" href="#"> links styled to look like buttons.
//

// SASS Core + 3rd Party Libs
// -------------------------------
// @use 'sass:math';
// -------------------------------

// Dependencies
// -------------------------------
@use '../settings' as *;
@use '../utils/focus' as *;
@use '../utils/relative-type' as *;
// -------------------------------

@mixin button-reset {
    @include focus($opacity: true, $transform: true);
    background: transparent;
    color: inherit;
    cursor: pointer;
    display: inline-block;
    padding: $gutter-s;
    text-decoration: none;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
}
