// -------------------------------
// Footer
// -------------------------------

// SASS Core + 3rd Party Libs
// -------------------------------
// @use 'sass:math';
// -------------------------------

// Dependencies
// -------------------------------
@use '../settings' as *;
@use '../utils' as *;
// -------------------------------

.footer {
    padding-block: $gutter-clamp-xl;

    &__wrapper {
        @include breakpoint(medium, min) {
            flex-direction: row;
        }
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: $gutter-clamp-l;
        justify-content: center;
    }

    &__nav {
        .flex {
            @include breakpoint(medium, min) {
                --flex-gap: #{$gutter-clamp-l};
                flex-direction: row;
            }
            align-items: center;
            flex-direction: column;
        }
    }

    &__item {
        &:not(:last-child) {
            @include breakpoint(medium, min) {
                position: relative;

                /* stylelint-disable-next-line max-nesting-depth */
                ::after {
                    content: '|';
                    // -1px takes into account the width of the | character.
                    inset-inline-end: calc(#{$gutter-clamp-l} * -0.5 - 1px);
                    position: absolute;
                }
            }
        }
    }

    &__link {
        @include focus;
        color: inherit;
        text-decoration: none;

        &:hover {
            color: $brand;
            text-decoration: underline;
        }
    }
}
