// -------------------------------
// Button
// -------------------------------

// SASS Core + 3rd Party Libs
// -------------------------------
// @use 'sass:math';
// -------------------------------

// Dependencies
// -------------------------------
@use '../utils' as *;
@use '../settings' as *;
// -------------------------------

.button {
    @include button-reset;

    &[class*='text'] {
        border: rem(1) solid;
        border-radius: rem(40);
        font-size: rem($base-font-size);
        padding: $gutter-m $gutter-clamp-l;
    }

    &--text-icon {
        align-items: center;
        display: inline-flex;
        gap: $gutter-s;
        justify-content: space-between;

        span {
            text-align: start;
        }
    }

    &--reverse {
        flex-direction: row-reverse;
    }

    // Apply colours to button variants.
    &--primary {
        background-color: $brand;
        color: $white;

        &[class*='text'] {
            border-color: $brand;
        }
    }

    &--secondary {
        color: $brand;

        &[class*='text'] {
            border-color: $brand;
        }
    }

    &--positive {
        background-color: $success;

        &[class*='text'] {
            border-color: $success;
        }
    }

    &--negative {
        background-color: $error;
        color: $white;

        &[class*='text'] {
            border-color: $error;
        }
    }

    &--icon {
        align-items: center;
        display: inline-flex;
        justify-content: center;
    }

    // Text (and text + icons) buttons only. Icon button sizes are determined by the context of their parent container font-size (or their icon size overrides).
    &--small {
        font-size: rem($font-size-small);
        padding: $gutter-xs;
    }
}
